<template>
  <div>
    <v-divider class="my-4"></v-divider>
    <v-col cols="12"
           xl="8"
           lg="12"
           class="px-0">
      <v-row class="mx-n2"
             v-if="isCompanyLoaded">
        <v-col cols="12"
               sm="6"
               md="4"
               class="pa-2">
          <div class="companyDetailsWrapper">
            <p class="label caption">
              <LocalizedLabel>partner</LocalizedLabel>
            </p>
            <p class="value caption subtitle--text">
              {{mappedCompanyDetails.partnerDisplayName}}</p>
          </div>
        </v-col>
        <v-col cols="12"
               sm="6"
               md="4"
               class="pa-2">
          <div class="companyDetailsWrapper"
               @mouseover="isTenantIdCopyBtnVisible = true"
               @mouseleave="isTenantIdCopyBtnVisible = false">
            <p class="label caption">
              <LocalizedLabel>tenantId</LocalizedLabel>
              <v-btn icon
                     x-small
                     class="pa-0 ma-0 copyButton"
                     v-if="isTenantIdCopyBtnVisible && companyDetails.id"
                     @click="copy(mappedCompanyDetails.id)">
                <v-icon color="subtitle">mdi-content-copy</v-icon>
              </v-btn>
            </p>
            <p class="value caption oneliner subtitle--text">
              {{mappedCompanyDetails.id}}
            </p>
          </div>
        </v-col>
        <v-col cols="12"
               sm="6"
               md="4"
               class="pa-2"
               v-if="isSevenPartner">
          <div class="companyDetailsWrapper"
               @mouseover="isSevenCompanyIdCopyBtnVisible = true"
               @mouseleave="isSevenCompanyIdCopyBtnVisible = true">
            <p class="label caption">
              <LocalizedLabel>sevenCompanyId</LocalizedLabel>
              <v-btn icon
                     x-small
                     class="pa-0 ma-0 copyButton"
                     v-if="isSevenCompanyIdCopyBtnVisible"
                     @click="copy(mappedCompanyDetails.sevenCompanyId)">
                <v-icon color="subtitle">mdi-content-copy</v-icon>
              </v-btn>
            </p>
            <p class="value caption oneliner subtitle--text">
              {{mappedCompanyDetails.sevenCompanyId}}
            </p>
          </div>
        </v-col>
        <v-col cols="12"
               sm="6"
               md="4"
               class="pa-2"
               v-if="isSevenPartner">
          <div class="companyDetailsWrapper">
            <p class="label caption">
              <LocalizedLabel>walletDc</LocalizedLabel>
            </p>
            <p class="value caption subtitle--text">
              {{mappedCompanyDetails.sevenWalletDC}}</p>
          </div>
        </v-col>
        <v-col cols="12"
               sm="6"
               md="4"
               class="pa-2"
               v-if="isIsoftbetPartner">
          <div class="companyDetailsWrapper"
               @mouseover="isIsoftbetLicenseeIdCopyBtnVisible = true"
               @mouseleave="isIsoftbetLicenseeIdCopyBtnVisible = false">
            <p class="label caption">
              <LocalizedLabel>licenseeId</LocalizedLabel>
              <v-btn icon
                     x-small
                     class="pa-0 ma-0 copyButton"
                     v-if="isIsoftbetLicenseeIdCopyBtnVisible"
                     @click="copy(mappedCompanyDetails.isoftbetLicenseeId)">
                <v-icon color="subtitle">mdi-content-copy</v-icon>
              </v-btn>
            </p>
            <p class="value caption oneliner subtitle--text">
              {{mappedCompanyDetails.isoftbetLicenseeId}}
            </p>
          </div>
        </v-col>
        <v-col cols="12"
               sm="6"
               md="4"
               class="pa-2"
               v-if="isIsoftbetPartner">
          <div class="companyDetailsWrapper">
            <p class="label caption">
              <LocalizedLabel>walletUrl</LocalizedLabel>
            </p>
            <p class="value caption subtitle--text">
              {{mappedCompanyDetails.isoftbetWalletUrl}}</p>
          </div>
        </v-col>
        <v-col cols="12"
               sm="6"
               md="4"
               class="pa-2"
               v-if="isDualSoftPartner">
          <div class="companyDetailsWrapper">
            <p class="label caption">
              <LocalizedLabel>walletCode</LocalizedLabel>
            </p>
            <p class="value caption subtitle--text">
              {{mappedCompanyDetails.dualsoftWalletCode}}</p>
          </div>
        </v-col>
        <v-col cols="12"
               sm="6"
               md="4"
               class="pa-2"
               v-if="isDualSoftPartner">
          <div class="companyDetailsWrapper">
            <p class="label caption">
              <LocalizedLabel>walletUrl</LocalizedLabel>
            </p>
            <p class="value caption subtitle--text">
              {{mappedCompanyDetails.dualsoftWalletUrl}}</p>
          </div>
        </v-col>
        <v-col cols="12"
               sm="6"
               md="4"
               class="pa-2">
          <div class="companyDetailsWrapper">
            <p class="label caption">
              <LocalizedLabel>currencies</LocalizedLabel>
            </p>
            <p class="value caption subtitle--text">
              {{mappedCompanyDetails.currencies}}</p>
          </div>
        </v-col>
        <v-col cols="12"
               sm="6"
               md="4"
               class="pa-2">
          <div class="companyDetailsWrapper">
            <p class="label caption">
              <LocalizedLabel>jackpot</LocalizedLabel>
            </p>
            <p class="value caption subtitle--text">
              {{mappedCompanyDetails.jackpotActive}}</p>
          </div>
        </v-col>
        <v-col cols="12"
               sm="6"
               md="4"
               class="pa-2">
          <div class="companyDetailsWrapper"
               :class="isJackpotInfoDisabled">
            <p class="label caption">
              <LocalizedLabel>jackpotContribution</LocalizedLabel> %
            </p>
            <p class="value caption subtitle--text">
              {{mappedCompanyDetails.jackpotContribution}}</p>
          </div>
        </v-col>
        <v-col cols="12"
               sm="6"
               md="4"
               class="pa-2">
          <div class="companyDetailsWrapper"
               :class="isJackpotInfoDisabled">
            <p class="label caption">
              <LocalizedLabel>jackpotProbability</LocalizedLabel>
            </p>
            <p class="value caption subtitle--text">
              {{mappedCompanyDetails.jackpotProbability}}</p>
          </div>
        </v-col>
        <v-col class="pt-6 px-2 companyTabsWrapper">
          <v-tabs v-model="tab"
                  color="text"
                  background-color="transparent">
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab v-for="(type, key, index) in companyDetails.settings.gameTypes"
                   :key="index"
                   :ripple="false">
              {{ key }}
            </v-tab>
          </v-tabs>
          <v-divider></v-divider>
          <v-tabs-items v-model="tab">
            <v-tab-item transition="fade-transition"
                        reverse-transition="fade-transition"
                        eager
                        v-for="(gameType, key, index) in companyDetails.settings.gameTypes"
                        :key="index">
              <div v-for="(currency, key, index) in gameType.currencies"
                   :key="index">
                <v-card-title class="body-2 pb-1 pt-4 ma-0 pa-0">
                  {{key}}
                </v-card-title>
                <v-row class="pa-1 pb-2 ma-0">
                  <v-col cols="12"
                         sm="4"
                         class="pa-2"
                         v-for="(amount, index) in amountsList"
                         :key="index">
                    <div class="companyDetailsWrapper">
                      <p class="label caption">
                        <LocalizedLabel>{{amount.label}}</LocalizedLabel>
                      </p>
                      <p class="value caption subtitle--text"
                         v-html="formatCurrency(currency[amount.value])"></p>
                    </div>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  has,
  isEmpty,
  isNil,
  join,
  map,
} from 'lodash';
import { roundDecimals } from '@/utility';

export default {
  name: 'companyDetails',
  data() {
    return {
      companyDetails: {},
      isTenantIdCopyBtnVisible: false,
      isExternalIdCopyBtnVisible: false,
      isSevenCompanyIdCopyBtnVisible: false,
      isIsoftbetLicenseeIdCopyBtnVisible: false,
      tab: null,
      amountsList: [
        { label: 'minStake', value: 'minBetAmount' },
        { label: 'maxStake', value: 'maxBetAmount' },
        { label: 'maxWinLimit', value: 'maxWinAmount' },
      ],
    };
  },
  methods: {
    ...mapActions([
      'loadData',
    ]),
    copy(item) {
      navigator.clipboard.writeText(item);
    },
    getJackpotStatus(value) {
      return has(value, 'active') ? value.active : true;
    },
    formatCurrency(value) {
      if (isNil(value)) {
        return '&infin;';
      }
      return roundDecimals(value);
    },
  },
  computed: {
    ...mapGetters([
      'partners',
      'translations',
    ]),
    isCompanyLoaded() {
      return !isEmpty(this.companyDetails);
    },
    mappedCompanyDetails() {
      if (this.companyDetails) {
        const list = map([this.companyDetails], (company) => ({
          ...company,
          partnerDisplayName: company.partnerDisplayName || '-',
          externalId: company.externalId || '-',
          currencies: join(company.currencies, ', '),
          jackpotActive: this.getJackpotStatus(company.settings.jackpot)
            ? this.translations.enabled : this.translations.disabled,
          jackpotContribution: company.settings.jackpot.contribution || '-',
          jackpotProbability: company.settings.jackpot.probabilityPct
            ? parseFloat(1 / company.settings.jackpot.probabilityPct).toFixed() : '-',
          id: company.id || '-',
          sevenWalletDC: company.meta?.sevenWalletDC || '-',
          sevenCompanyId: company.meta?.sevenCompanyId || '-',
          isoftbetLicenseeId: company.meta?.isoftbetLicenseeId || '-',
          isoftbetWalletUrl: company.meta?.isoftbetWalletUrl || '-',
          dualsoftWalletCode: company.meta?.dualsoftWalletCode ? join(company.meta.dualsoftWalletCode, ', ') : '-',
          dualsoftWalletUrl: company.meta?.dualsoftWalletUrl || '-',
        }));
        return list[0];
      }
      return {};
    },
    isJackpotInfoDisabled() {
      return !this.getJackpotStatus(this.companyDetails.settings.jackpot) ? 'disabledInfo' : '';
    },
    isSevenPartner() {
      const sevenPartner = this.partners.find((partner) => partner.displayName === 'Seven');

      return this.companyDetails.partnerId === sevenPartner?.id;
    },
    isDualSoftPartner() {
      const dualSoftPartner = this.partners.find((partner) => partner.displayName === 'DualSoft');

      return this.companyDetails.partnerId === dualSoftPartner?.id;
    },
    isIsoftbetPartner() {
      const isoftbetPartner = this.partners.find((partner) => partner.displayName === 'iSoftBet');

      return this.companyDetails.partnerId === isoftbetPartner?.id;
    },
  },
  async created() {
    const response = await this.loadData({
      path: `tenants/${this.$route.query.companyId}`,
    });
    if (response) {
      this.companyDetails = response.data;
    }
  },
};
</script>

<style lang="scss" scoped>
.companyDetailsWrapper {
  border: 1px solid rgba(128, 128, 128, 0.3);
  border-radius: 4px;
  padding: 3px;
  .label,
  .value {
    margin: 0 15px;
  }
  .oneliner {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .copyButton {
    float: right;
  }
}
.disabledInfo p {
  opacity: 0.5;
}
</style>
